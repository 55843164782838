const pushState = history.pushState;
history.pushState = function () {
	pushState.apply(history, arguments);
	window.dispatchEvent(new Event("tvfchangeurl"));
};
const replaceState = history.replaceState;
history.replaceState = function () {
	replaceState.apply(history, arguments);
	window.dispatchEvent(new Event("tvfchangeurl"));
};
