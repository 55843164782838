const deepEqual = require("deep-equal");

export default (props1, props2) => {
	const currUser = props1.withAuthConsumer_myUserDetails;
	const oldUser = props2.withAuthConsumer_myUserDetails;
	if (currUser && !oldUser) {
		return true;
	}
	if (oldUser && !currUser) {
		return true;
	}
	if (!oldUser && !currUser) {
		return false;
	}
	return !deepEqual(oldUser, currUser);
};
