import React from "react";
import { withRouter } from "react-router-dom";
import getUrl from "../../common/getUrl";
import withApiConsumer from "../../common/withApiConsumer";
import withAuthConsumer from "../../common/withAuthConsumer";
import Modal from "../../widgets/Modal";
import idGenerate from "../../common/idGenerate";
import styled from "styled-components";
import theme from "../../common/theme";
import { Button } from "../Layout/Button";
import Loader from "../Loader";

const disabledPages = new Set(["/sign-in", "/register", "/terms-of-use", "/privacy-policy", "/privacy-notice"]);

const Form = styled.form`
	margin-bottom: 2em;
`;

const Label = styled.label`
	display: block;
	margin-bottom: 1em;
`;

const NiceLink = styled.a`
	color: ${theme.colours.primary};
	font-weight: bold;
`;

const H4 = styled.h4`
	margin-top: -1em;
	margin-bottom: 0.7em;
`;

export default withApiConsumer(
	withAuthConsumer(
		withRouter(
			class PrivacyOrTermsUpdatedModal extends React.PureComponent {
				_id = idGenerate();

				state = {
					loading: false,
				};

				onPressAgree = (e) => {
					e.preventDefault();
					if (this.state.loading) {
						return;
					}
					this.setState({ loading: true }, () => {
						this.props.api("/public/page-set-agreed").then(() => {
							window.location.reload(true);
						});
					});
				};

				render() {
					const me = this.props.withAuthConsumer_myUserDetails;
					if (!me) {
						return null;
					}
					if (me.role === "guest") {
						return null;
					}
					if (!me.has_privacy_notice_changed && !me.has_terms_of_use_changed && me.has_seen_privacy_or_terms) {
						return null;
					}
					if (disabledPages.has(this.props.location.pathname)) {
						return null;
					}
					return (
						<Modal modalWidth="764px" show={true} showCloseLink={false}>
							<Form onSubmit={this.onPressAgree}>
								{this.state.loading && <Loader />}
								{!this.state.loading && (
									<>
										{!me.has_seen_privacy_or_terms && (
											<>
												<H4>We need you to agree to the Terms of Use and Privacy Policy before you can use the Education Platform.</H4>
											</>
										)}
										{me.has_seen_privacy_or_terms && me.has_privacy_notice_changed && me.has_terms_of_use_changed && (
											<>
												<H4>The Education Platform Terms of Use and Privacy Policy has been updated since you last logged in.</H4>
												<p>
													The new version of the Terms of Use can be read here:{" "}
													<NiceLink href="/terms-of-use" target="_blank">
														{getUrl("/terms-of-use")}
													</NiceLink>
												</p>
												<p>
													The new version of the Privacy Policy can be read here:{" "}
													<NiceLink href="/privacy-notice" target="_blank">
														{getUrl("/privacy-notice")}
													</NiceLink>
												</p>
											</>
										)}
										{me.has_seen_privacy_or_terms && me.has_privacy_notice_changed && !me.has_terms_of_use_changed && (
											<>
												<H4>The Education Platform Privacy Policy has been updated since you last logged in.</H4>
												<p>
													The new version of the Privacy Policy can be read here:{" "}
													<NiceLink href="/privacy-notice" target="_blank">
														{getUrl("/privacy-notice")}
													</NiceLink>
												</p>
											</>
										)}
										{me.has_seen_privacy_or_terms && !me.has_privacy_notice_changed && !me.has_terms_of_use_changed && (
											<>
												<H4>The Education Platform Terms of Use has been updated since you last logged in.</H4>
												<p>
													The new version of the Terms of Use can be read here:{" "}
													<NiceLink href="/terms-of-use" target="_blank">
														{getUrl("/terms-of-use")}
													</NiceLink>
												</p>
											</>
										)}
										<Label htmlFor={this._id}>
											<input required={true} id={this._id} type="checkbox" name="agree" />
											&nbsp;
											<strong>I Agree</strong> to CLA's{" "}
											<NiceLink href="/terms-of-use" target="_blank">
												Terms of Use
											</NiceLink>{" "}
											and{" "}
											<NiceLink href="/privacy-notice" target="_blank">
												Privacy Policy
											</NiceLink>
										</Label>
										<Button type="submit" disabled={this.state.loading}>
											Submit
										</Button>
									</>
								)}
							</Form>
						</Modal>
					);
				}
			}
		)
	)
);
