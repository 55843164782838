import styled from "styled-components";
import { colXs12, colSm6, colLg3 } from "../../common/style";
import theme from "../../common/theme";

export const FooterColumn = styled.div`
	${colXs12}
	${colSm6}
	${colLg3}

	@media screen and (min-width: ${theme.breakpoints.mobileLarge}) {
		flex: 0 0 25%;
		max-width: 25%;
	}
`;
