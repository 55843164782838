const theme = {
	colours: {
		primary: "#006473",
		primaryLight: "#107381",
		primaryDark: "#005467",
		secondary: "#3cadc4",
		red: "#FF5A70",
		white: "#ffffff",
		cla: "#b4d000",
		formBackground: "rgba(211, 211, 211, 0.27)",
		signInBackGround: "#DAE99C",
		footerBackGrounColor: "#005863",
		lime: "#EAF2C5",
		limeLight: "#F8FFD3",
		smoky: "#595170",
		darkGray: "#424242",
		fbColor: "#3451CF",
		twitterColor: "#4CB2FD",
		bgDarkPurple: "#3E3A4A",
		errorColor: "#D04711",
		messageWarning: "#fcf8e3",
		fgColorWarning: "#8a6d3b",
		fgColorSuccess: "#3c763d",
		black: "#000000",
		gray: "#767676",
		lightGray: "#c5c5c5",
		lightGreen: "#90c418",
		flyOutModalPrimary: "#2A7AB0",
		noteYellow: "#fbf99b",
		noteGreen: "#99f997",
		notePink: "#f18ef2",
		noteBlue: "#91fdfd",
		bgGray: "#eaeaea",
		focusFomControlBorder: "#80bdff",
		warning: "#e37222",
		grayTextColor: "#999999",
		bgSaSuccess: "#a5dc86",
		errorTextColor: "#cc0000",
		blogAnchorLink: "#408b8a",
		favIconTextColor: "#bc5267",
		bgCheckbox: "#2196f3",
		bgBtnContentRequest: "#9c2169",
	},
	shadow: "0 8px 25px rgba(0,0,0,0.15)",
	headerHeight: "4em",
	headerHeightDesktop: "6em",
	breakpoints: {
		desktop3: "1300px",
		laptop: "1024px",
		mobileLarge: "768px",
		mobile: "640px",
		mobileSmall: "576px",
		mobile4: "400px",
	},
	siteWidth: 1500,
	padding: "1em",
	flyOutWidth: 380,
	flyOutModal: {
		width: "760px",
	},
};

export default theme;
export const { colours } = theme;
